import { useIntegrations, useProximaSDK, useQuery } from 'hooks';
import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { useAdAccountStore, useBrandStore } from 'stores';
import { useNavigate, useParams } from 'react-router-dom';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useIntegrationApi } from 'api';
import { useState } from 'react';
import { ProximaApiResponseCode } from 'types/hooks/useHandleApi';
import { Integration } from 'types/stores/integration-store';

const useTiktok = () => {
  const { getQueryParams } = useQuery();
  const { getActiveIntegrations, getAllIntegrationsByType } = useIntegrations();
  const adAccountStore = useAdAccountStore();
  const navigate = useNavigate();
  const { brandId: paramsBrandId } = useParams<{ brandId?: string }>();
  const { brand } = useBrandStore();
  const { showToaster } = useToaster();
  const tiktokIntegrationApi = useProximaSDK('TiktokIntegrationApi');
  const integrationApi = useIntegrationApi();

  const [pollCount, setPollCount] = useState(0);

  const { brandId: queryBrandId } = getQueryParams<{ brandId: string }>();

  const brandId = paramsBrandId ?? brand?.brandId ?? queryBrandId;

  const getTiktokStatus = () => {
    const tiktokIntegrations = getAllIntegrationsByType(IntegrationType.TikTok);

    const neverConnected = tiktokIntegrations.length === 0;

    if (neverConnected) return BrandIntegrationStatus.NeverConnected;

    const [hasActiveIntegration] = getActiveIntegrations(IntegrationType.TikTok);

    const hasAdAccount = adAccountStore.tiktokAdAccount?.accountId;
    const isConnected = hasActiveIntegration && hasAdAccount;
    const isIncomplete = hasActiveIntegration && !hasAdAccount;

    if (isIncomplete) return BrandIntegrationStatus.Incomplete;
    if (isConnected) return BrandIntegrationStatus.Connected;

    return BrandIntegrationStatus.Disconnected;
  };

  const pollForIntegration = () => {
    const interval = setInterval(async () => {
      setPollCount(pollCount + 1);

      const result = await integrationApi.getIntegrations(brandId);

      const integrations = result.data as Integration[];

      const tiktokIntegration = integrations.find(
        (integration: Integration) => integration.type === IntegrationType.TikTok
      );

      if (tiktokIntegration) {
        clearInterval(interval);

        showToaster({
          variant: 'success',
          message: 'TikTok successfully added',
        });

        setTimeout(() => {
          navigate('/auth/callback/tiktok/choose-ad-account');
        }, 2000);
      }

      // Once we reach a poll count of 30 (should equate to ~30 seconds), throw an error
      if (pollCount >= 30) {
        clearInterval(interval);

        showToaster({ variant: 'warning', message: 'Unable to process TikTok integration' });
      }
    }, 1000);
  };

  const extractOAuthParameters = () => {
    /* eslint-disable @typescript-eslint/naming-convention */
    const { auth_code, state } = getQueryParams();

    if (!auth_code || !state) {
      showToaster({ message: 'Invalid OAuth URL', variant: 'warning' });

      return null;
    }

    return {
      auth_code,
      state,
    };
  };

  const setTiktokAccessToken = async () => {
    const data = extractOAuthParameters();

    if (data) {
      const result = await tiktokIntegrationApi.createIntegration1({
        brandId,
        createIntegrationInput: {
          code: data.auth_code,
          state: data.state,
        },
      });

      if (result.status === ProximaApiResponseCode.NoContent) {
        pollForIntegration();
      } else {
        // Request to set access token failed
        navigate(`/brand/${brandId}/integrations${window.location.search}`, { replace: true });
      }
    }
  };

  const onLoginToTiktok = async () => {
    const redirectUri = encodeURIComponent(
      `${window.location.origin}/auth/callback/tiktok/integrate`
    );

    const res = await tiktokIntegrationApi.generateOAuthUrl({
      generateOAuthUrlRequest: { brandId, redirectUrl: redirectUri },
    });

    window.open(`${res.data.url}`, '_self');
  };

  return {
    getTiktokStatus,
    onLoginToTiktok,
    extractOAuthParameters,
    setTiktokAccessToken,
  };
};

export default useTiktok;
