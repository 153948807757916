import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { TIKTOK_PRIVACY_POLICIES_URL, TIKTOK_TERM_POLICIES_URL } from 'constants/urls';
import { Button, Text } from '@innovationdepartment/proxima-ui';
import { TiktokIntegrationModalProps } from 'types/components/integrations';
import SideContentContainer from '../IntegrationsModal/LayoutComponents/SideContentContainer';
import SideContentTermsAndPrivacy from '../IntegrationsModal/LayoutComponents/SideContentTermsAndPrivacy';
import SideContentCategories from '../IntegrationsModal/LayoutComponents/SideContentCategories';
import IntegrationsModalStatus from '../IntegrationsModal/LayoutComponents/IntegrationsModalStatus';
import SideContentItemWrapper from '../IntegrationsModal/LayoutComponents/SideContentItemWrapper';
import styled from '@emotion/styled';

const CATEGORIES = ['Social', 'Marketing'];

const LINKS = {
  terms: TIKTOK_TERM_POLICIES_URL,
  privacy: TIKTOK_PRIVACY_POLICIES_URL,
};

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const ExtraContentWrapper = styled.div`
  width: 100%;
  & > :last-child {
    margin: 16px 0;
  }
`;

const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TiktokIntegrationModalSideContent = (props: {
  loading: TiktokIntegrationModalProps['loading'];
  status: TiktokIntegrationModalProps['status'];
  adAccount: TiktokIntegrationModalProps['adAccount'];
  onConnectAdAccountClick: TiktokIntegrationModalProps['onConnectAdAccountClick'];
  onDisconnectClick: TiktokIntegrationModalProps['onDisconnectClick'];
  onGetStartedClick: TiktokIntegrationModalProps['onGetStartedClick'];
}) => {
  const {
    loading,
    adAccount,
    status,
    onConnectAdAccountClick,
    onDisconnectClick,
    onGetStartedClick,
  } = props;
  const isIncomplete = status === BrandIntegrationStatus.Incomplete;
  const isConnected = status === BrandIntegrationStatus.Connected;

  const swapTopContent = isConnected || isIncomplete;

  let topContent: React.ReactNode = (
    <ConnectWrapper>
      <IntegrationsModalStatus type={IntegrationType.TikTok} status={status} required />
      {!isConnected && onGetStartedClick && (
        <Button size="small" fullWidth label="Get started" onClick={onGetStartedClick} />
      )}
    </ConnectWrapper>
  );

  const disconnectButton: React.ReactNode = swapTopContent && (
    <Button
      size="small"
      variant="outlined"
      color="error"
      fullWidth
      disabled={loading}
      label={loading ? 'Disconnecting...' : 'Disconnect'}
      onClick={() => !loading && onDisconnectClick()}
    />
  );

  const connectAdAccountContent: React.ReactNode = (
    <ExtraContentWrapper>
      <Button
        fullWidth
        variant="outlined"
        onClick={onConnectAdAccountClick}
        label="Select Ad Account"
      />
    </ExtraContentWrapper>
  );

  if (swapTopContent) {
    topContent = (
      <SideContentItemWrapper flow="column" gap={16}>
        <StatusWrapper>
          {status && <IntegrationsModalStatus type={IntegrationType.TikTok} status={status} />}
          {isIncomplete && connectAdAccountContent}
        </StatusWrapper>
        <SideContentItemWrapper flow="column" gap={8}>
          <SideContentItemWrapper flow="column" gap={0}>
            <Text variant="body2" color="neutral100">
              Ad account
            </Text>
            <Text variant="body2" color="neutral60">
              {adAccount?.name}
            </Text>
          </SideContentItemWrapper>
          <SideContentItemWrapper flow="column" gap={0}>
            <Text variant="body2" color="neutral100">
              Ad account ID
            </Text>
            <Text variant="body2" color="neutral60">
              {adAccount?.accountId}
            </Text>
          </SideContentItemWrapper>
        </SideContentItemWrapper>
      </SideContentItemWrapper>
    );
  }

  return (
    <SideContentContainer>
      {topContent}
      <SideContentCategories categories={CATEGORIES} />
      {disconnectButton}
      <SideContentTermsAndPrivacy {...LINKS} />
    </SideContentContainer>
  );
};

export default TiktokIntegrationModalSideContent;
