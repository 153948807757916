import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { CellRendererProps, TableProps } from '@innovationdepartment/proxima-ui';
import { IApiResponse } from 'types/hooks/useHandleApi';
import {
  AudiencesCampaign,
  CampaignStatus,
  CampaingInput,
  GenerateNewCampaignViewProps,
  SkipCampaignModalProps,
} from './audienceCampaign';

export type FlavorCategory = {
  id: string;
  name: string;
  categoryId: string | null;
  categoryName: string | null;
  createdAt?: Date | string;
  updatedAt?: Date | string;
};

export type Galaxy = {
  id: string;
  name: string;
  flavor: FlavorCategory;
  flavorId: string;
  createdAt: string;
  updatedAt: string;
};

// these are the statuses we use for the components state
export enum AudienceStatus {
  New = 'new',
  Processing = 'processing',
  Ready = 'ready',
}

export enum LookalikeAudienceStatus {
  New = 'New', // Awaiting seed data generation
  Generating = 'Generating', // Seed data is being generated
  Seeding = 'Seeding', // Seed data is being uploaded to the platform
  Delivered = 'Delivered', // Custom audience has been generated and shared with the customer and is ready to be used
  Error = 'Error', // There was an error at any step in the process
  Active = 'Active', // Custom audience has spend increase since last sync
  Inactive = 'Inactive', // Custom audience has no spend increase since last sync
  Deleted = 'Deleted', // Custom audience has been deleted
  /* frontend custom ones */
  Processing = 'Processing', // Custom audience is being processed
}

export type IntegrationAudience = {
  id: string;
  seedAudienceId: string;
  brandId: string;
  adAccountId: string;
  createdByUserId: string;
  integrationType: IntegrationType;
  externalId: string;
  importedAt: string | null;
  lifetimeSpend: number;
  name: string;
  status: string;
  errorCode: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type LookalikeAudience = {
  id: string;
  name: string;
  brandId: string;
  integrationType: IntegrationType;
  size: number;
  importedAt: string;
  displayStatus: LookalikeAudienceStatus;
  status: string; // TODO(Jenky): get these from the backend
  lifetimeSpend: number;
  targetCountry: string;
  createdAt: string;
  updatedAt: string;
};

type SeedAudienceType = 'selfService';

export enum SeedAudienceStatus {
  New = 'new',
  Generating = 'generating',
  Delivered = 'delivered',
  Error = 'error',
}
export type CreateSeedAudienceResponse = {
  audienceGroupId: string;
  seedAudiences: SeedAudience[];
};

export type SeedAudience = {
  id: string;
  name: string;
  brandId: string;
  type: SeedAudienceType;
  galaxyId: Galaxy['id'];
  flavorId: string;
  categoryId: string;
  categoryName: string;
  status: SeedAudienceStatus;
  lifetimeSpend: number;
  errorCode: string;
  integrationAudiences: IntegrationAudience[];
  lookalikeAudiences: LookalikeAudience[];
  createdAt: string;
  updatedAt: string;
  audienceGroupId: string;
};

export type CreateSeedAudienceSchema = {
  integrations: {
    adAccountId: string;
    integrationType: IntegrationType;
    size: number;
  }[];
  galaxyIds: Galaxy['id'][];
  seedAudienceType: SeedAudienceType;
};

export type FetchSeedAudienceSchema = {
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortDirection: string;
  seedAudiences: SeedAudience[];
};

export type CreateLookalikeAudienceSchema = {
  seedAudienceId: string;
  integrationType: IntegrationType;
  size: number;
};

/* ------ ---------------- ------ */
/* ------ components types ------ */
/* ------ ---------------- ------ */
export type AudiencesProps = {
  onRetryAudience: (args: { seedAudienceId: string; lookalikeAudienceId?: string }) => void;
  showNewGalaxiesBadge: boolean;
  seedAudiences: SeedAudience[];
  showCreateLookalikeModal: (row: SeedAudience) => void;
  showCreateModal: (showModal?: boolean) => void;
};

export type AudiencesRecommendedActionsProps = {
  showCreateModal: AudiencesProps['showCreateModal'];
  showNewGalaxiesBadge: AudiencesProps['showNewGalaxiesBadge'];
};

/* seed audience table components props */
export type SeedAudiencesTableProps = {
  onRetryAudience: AudiencesProps['onRetryAudience'];
  seedAudiences: AudiencesProps['seedAudiences'];
  showCreateModal: AudiencesProps['showCreateModal'];
  showCreateLookalikeModal: AudiencesProps['showCreateLookalikeModal'];
};

export type SeedAudiencesTableViewProps = {
  seedAudiences: AudiencesProps['seedAudiences'];
  config: TableProps<SeedAudience>['config'];
};

/* lookalike audience table component props */
export type LookalikeAudiencesTableProps = {
  seedAudience: SeedAudience;
  onRetryAudience: AudiencesProps['onRetryAudience'];
  lookalikeAudiences: LookalikeAudience[];
};

export type LookalikeAudiencesTableViewProps = {
  lookalikeAudiences: LookalikeAudience[];
  config: TableProps<LookalikeAudience>['config'];
};

/* other components */
/* modals */
export type CreateNewAudiencesModalProps = {
  status: AudienceStatus;
  show: boolean;
  onCreateNewSeedAudiences: (
    data: CreateSeedAudienceSchema
  ) => Promise<IApiResponse<CreateSeedAudienceResponse> | undefined>;
  onClose: () => void;
  requestAdAccount: () => Promise<void>;
  audiencesCampaign: AudiencesCampaign | undefined;
  campaignStatus: CampaignStatus;
  onCreateNewCampaign: (
    input: CampaingInput,
    audienceGroupId: string
  ) => Promise<IApiResponse<any> | undefined>;
  stepType: ModalStep;
};

export type CreateNewAudiencesModalViewProps = CreateNewAudiencesModalProps & {
  onStatusChange: (status: AudienceStatus) => void;
  onCampaignStatusChange: (status: CampaignStatus) => void;
  onFormSkipClick: GenerateNewCampaignViewProps['onFormSkipClick'];
  showSkipCampaignModal: boolean;
  onSkipModalClose: SkipCampaignModalProps['onSkipModalClose'];

  title: string;
  subtitle: string;
  currentFeedbackStep: ModalStep;
  showAudienceStep: boolean;
  showCampaignStep: boolean;
  showFeedbackStep: boolean;
  onHandleClose: () => void;
  onSkipModal: SkipCampaignModalProps['onSkipModal'];
};

export type CreateNewLookalikeModalProps = {
  onCreateNewLookalikeAudience: (
    data: Omit<CreateLookalikeAudienceSchema, 'seedAudienceId'>
  ) => Promise<void>;
  onClose: () => void;
  seedAudience?: SeedAudience;
};

export type CreateNewLookalikeModalViewProps = Omit<
  CreateNewLookalikeModalProps,
  'onCreateNewLookalikeAudience'
> & {
  isSubmitting: boolean;
  loading: boolean;
  sizes: LookalikeSize[];
};

/* seed audience creation props for modal */
export type GenerateAudiencesForm = {
  integrationType: IntegrationType;
  lookalikeSize: number;
  galaxies: Galaxy[];
};

export type GenerateNewAudiencesProps = {
  requestAdAccount: CreateNewAudiencesModalViewProps['requestAdAccount'];
  onCreateNewSeedAudiences: CreateNewAudiencesModalViewProps['onCreateNewSeedAudiences'];
  onStatusChange: CreateNewAudiencesModalViewProps['onStatusChange'];
};

export type GenerateNewAudiencesViewProps = {
  loading: boolean;
};

/* status view for the modal  */
export type AudiencesQueryStringKeys = 'generateAudiences' | 'integrationType' | 'lookalikeSize';

export type GeneratedAudiencesStatusProps = {
  status: AudienceStatus;
  campaignStatus: CampaignStatus;
  onClose: CreateNewAudiencesModalProps['onClose'];
  stepType: ModalStep;
};

export enum ModalStep {
  Audience = 'audience',
  Campaign = 'campaign',
  None = 'none',
}

/* ad targeting (enhancement section) */
export type AdEnhancementViewProps = {
  connectToShopify: () => void;
  status: BrandIntegrationStatus;
};

/* ad platform selection */
export type AdPlatform = {
  label: string;
  value: IntegrationType;
  icon: React.ReactNode;
  disabled?: boolean;
  helperText?: string;
};

export type AdPlatformSelectionViewProps = {
  platforms: AdPlatform[];
  selectedPlatform: IntegrationType;
  onSelectPlatform: (platform: IntegrationType) => void;
  onTikTokOptionClick: () => void;
};

/* lookalike source (galaxies section) */
export type LookalikeSourcesViewProps = {
  loading: boolean;
  newGalaxies: boolean;
  galaxies: Galaxy[];
  lastUpdated: string | null;
};

export type LookalikeSourcesAudiencesLookalikeProps = {
  galaxy: Galaxy;
  size?: number;
};

/* lookalike sizes (sources size selection) */
export type LookalikeSize = {
  integrationType: IntegrationType;
  sizes: number[];
};

export type LookalikeSizeProps = {
  loading: boolean;
  disabled?: boolean;
  sizes: number[];
};

export type LookalikeSizesViewProps = Omit<LookalikeSizeProps, 'sizes' | 'integrationType'> & {
  loading: boolean;
  options: { value: string; label: string; disabled?: boolean }[];
};

/* lookalike sources data source info */
export type LookalikeSourcesDataSourceInfoViewProps = {
  loading: boolean;
  shopName?: string;
  flavorCategory?: FlavorCategory;
};

/* props for renderer function/singular cell components */
export type SeedAudienceTableColumnHeader =
  | 'seedAudience'
  | 'platform'
  | 'displayStatus'
  | 'dateCreated'
  | 'actions'
  | 'isHovered';
type SeedAudienceTableRowProperties = { [key in SeedAudienceTableColumnHeader]: true };

/* props for expand renderer function/singular cell components */
export type LookalikeAudienceTableColumnHeader =
  | 'lookalikeAudience'
  | 'platform'
  | 'displayStatus'
  | 'dateCreated';
type LookalikeAudienceTableRowProperties = { [key in LookalikeAudienceTableColumnHeader]: true };

export type SeedAudienceTableRowExtraFunctions = {
  showCreateLookalikeModal: SeedAudiencesTableProps['showCreateLookalikeModal'];
  onRetryAudience: AudiencesProps['onRetryAudience'];
};

export type SeedAudienceWithIntegrationTypes = SeedAudience & {
  integrationTypes: IntegrationType[];
};

export type SeedAudienceTableRowProps = SeedAudienceWithIntegrationTypes &
  SeedAudienceTableRowProperties;

export type LookalikeAudienceTableRowProps = LookalikeAudience &
  Omit<LookalikeAudienceTableRowProperties, 'displayStatus'>;

export type LookalikeAudienceTableRowExtras = {
  onRetryAudience: AudiencesProps['onRetryAudience'];
  seedAudience: SeedAudience;
};

export type AudienceTableCellRendererProps<T> = CellRendererProps<T>;

export type AudienceRowCellErrorStatusCell = {
  onRetry: () => void;
};
