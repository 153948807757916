import { Modal } from '@innovationdepartment/proxima-ui';
import { CustomTosStep } from 'types/components/audiencesCustomTos';
import { useAdAccountStore } from 'stores';
import { useCheckMetaCustomTos } from 'hooks';
import CustomAudienceTermsNotAcceptedModalView from './CustomAudienceTermsNotAcceptedModal.View';

type CustomAudienceTermsProps = {
  onClose: () => void;
  show: boolean;
};

const ANALYTICS_LOCATION = 'CustomAudienceTermsNotAcceptedModal';

const CustomAudienceTermsNotAcceptedModal = (props: CustomAudienceTermsProps) => {
  const { show, onClose } = props;
  const { metaAdAccount } = useAdAccountStore();

  const { loading, step, onAcceptTermsClick, onTermsAlreadyAcceptedClick } = useCheckMetaCustomTos({
    type: 'modal',
    show,
    adAccountId: metaAdAccount?.accountId,
    analytics: { location: ANALYTICS_LOCATION, area: 'CustomTermsOfServiceNotYetAccepted' },
  });

  const onButtonClick = async () => {
    if (step === CustomTosStep.AcceptTerms) onAcceptTermsClick();
    if (step === CustomTosStep.UserClickedAccepted) {
      const accepted = await onTermsAlreadyAcceptedClick();
      if (accepted) onClose();
    }
  };

  return (
    <Modal resizable open={show}>
      <CustomAudienceTermsNotAcceptedModalView
        step={step}
        loading={loading}
        onClose={onClose}
        onButtonClick={onButtonClick}
      />
    </Modal>
  );
};

export default CustomAudienceTermsNotAcceptedModal;
