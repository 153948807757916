import { useNavigate } from 'react-router-dom';
import { add, compareAsc } from 'date-fns';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useIntegrationApi, useOAuth } from 'api';
import { useBrandStore, useAdAccountStore } from 'stores';
import { useQuery, useIntegrations } from 'hooks';
import { IntegrationType, BrandIntegrationStatus } from '../constants/integrations';
import { IntegrationStatus } from 'types/stores/integration-store';

const useFacebook = () => {
  const adAccountStore = useAdAccountStore();
  const { getQueryParams } = useQuery();
  const { getAllIntegrationsByType } = useIntegrations();
  const navigate = useNavigate();
  const { brand } = useBrandStore();
  const { showToaster } = useToaster();
  const OAuth = useOAuth();
  const integrationApi = useIntegrationApi();

  const { brandId } = brand;

  const getMetaStatus = () => {
    const fbIntegrations = getAllIntegrationsByType(IntegrationType.Facebook);
    const neverConnected = fbIntegrations.length === 0;
    if (neverConnected) return BrandIntegrationStatus.NeverConnected;
    const hasActiveFBIntegration = fbIntegrations.some(
      (integration) => integration.status === IntegrationStatus.Active
    );
    const hasAdAccount = adAccountStore.metaAdAccount?.accountId;
    const isConnected = hasActiveFBIntegration && hasAdAccount;
    const isIncomplete = hasActiveFBIntegration && !hasAdAccount;
    const isTermsRequired = isConnected && !adAccountStore.metaAdAccount?.properties?.acceptedTos;
    if (isConnected) {
      if (isTermsRequired) return BrandIntegrationStatus.TermsRequired;
      return BrandIntegrationStatus.Connected;
    }
    if (isIncomplete) return BrandIntegrationStatus.Incomplete;
    return BrandIntegrationStatus.Disconnected;
  };

  const submitCodeAndCreateFBIntegration = async () => {
    const { code, facebookUserId, redirect, brandId: queryBrandId } = getQueryParams();
    const id = queryBrandId || brandId;

    /* TODO(Jenky): Might want to use SDK here once proxima.integrations.fb is merged in prod */
    const response = await OAuth.submitCodeForAccessToken(id, { code, facebookUserId });

    if (response.error) {
      showToaster({ message: 'Unable to save integration for Meta', variant: 'error' });
      navigate(-1);
      return;
    }

    navigate(`/brand/${id}/meta/ad-account?redirect=${redirect}`);
  };

  const isFacebookReconnect = () => {
    const reconnectTs = window.localStorage.getItem(`${brandId}-fb-reconnect-ts`);
    if (!reconnectTs) {
      return false;
    }

    const halfAnHourAfterReconnect = add(new Date(reconnectTs), { minutes: 30 });
    return compareAsc(halfAnHourAfterReconnect, new Date()) > 0;
  };

  const setIsFacebookReconnect = (value?: string | undefined) => {
    if (!value) {
      window.localStorage.removeItem(`${brandId}-fb-reconnect-ts`);
    } else {
      window.localStorage.setItem(`${brandId}-fb-reconnect-ts`, value);
    }
  };

  return {
    loading: integrationApi.loading,
    submitCodeAndCreateFBIntegration,
    getMetaStatus,
    isFacebookReconnect,
    setIsFacebookReconnect,
  };
};

export default useFacebook;
