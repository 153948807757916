import { PropsOf } from '@emotion/react';
import OnboardingView from './Onboarding.View';
import {
  Angler,
  GoogleAds,
  Klaviyo,
  MetaNoBorder,
  Shopify,
  Text,
  TikTok,
} from '@innovationdepartment/proxima-ui';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useConnectFacebookToProxima,
  useFeatureFlag,
  useIntegrations,
  useShopify,
  useTiktok,
  useAngler,
} from 'hooks';
import { IntegrationType } from 'constants/integrations';
import { useIntegrationsStore } from 'stores';
import { ShopifyAppRedirect } from 'types/components/shopify';
import { useEffect } from 'react';
import { IntegrationStatus } from 'types/stores/integration-store';

type IntegrationItems = PropsOf<typeof OnboardingView>['integrationsItems'];

const Onboarding = () => {
  const navigate = useNavigate();
  const { integrations } = useIntegrationsStore();
  const { initializeIntegrations } = useIntegrations();
  const { brandId } = useParams<{ brandId: string }>();
  const { setShopifyURLRedirect } = useShopify();
  const { onConnectAnglerClick } = useAngler();
  const { tiktokIntegration } = useFeatureFlag();
  const { onLoginToTiktok } = useTiktok();
  const { onClick: onFacebookConnectClick } = useConnectFacebookToProxima({
    redirect: `/brand/${brandId}/onboarding`,
    brandId,
  });

  const onKlaviyoConnectClick = () => {
    navigate(`/brand/${brandId}/klaviyo/connect-account?origin=onboarding`);
  };

  const onShopifyConnectClick = () => {
    setShopifyURLRedirect({ key: ShopifyAppRedirect.Onboarding });

    window.open(
      import.meta.env.VITE_SHOPIFY_APP_LISTING_URL || 'https://apps.shopify.com/',
      '_self'
    );
  };

  const isIntegrationConnected = (type: IntegrationType) =>
    integrations.some(
      (integration) => integration.type === type && integration.status === IntegrationStatus.Active
    );

  const integrationsItems: IntegrationItems = [
    {
      description:
        "Seamlessly add Proxima's audiences to your ad account and benchmark your Meta performance against similar businesses.",
      title: (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Text variant="h6" color="neutral90" isTermina={false}>
            Meta
          </Text>
          <div style={{ height: 20 }}>
            <Text variant="tag1Semibold" color="neutral70">
              (Required)
            </Text>
          </div>
        </div>
      ),
      icon: <MetaNoBorder />,
      type: IntegrationType.Facebook,
      isConnected: isIntegrationConnected(IntegrationType.Facebook),
      isRequired: true,
      loading: false,
      onClick: onFacebookConnectClick,
    },
    {
      description:
        'Add the Proxima app to your Shopify store. Once connected, you’ll be able to use Shopify data to build the highest caliber audiences and view advanced customer insights.',
      title: 'Shopify',
      icon: <Shopify />,
      type: IntegrationType.Shopify,
      pill: {
        label: '30% better results',
        variant: 'success',
      },
      isConnected: isIntegrationConnected(IntegrationType.Shopify),
      loading: false,
      onClick: onShopifyConnectClick,
    },
    {
      description: 'Enable enhanced customer insights and advanced targeting models.',
      title: 'Klaviyo',
      icon: <Klaviyo />,
      type: IntegrationType.Klaviyo,
      pill: {
        label: 'Early access',
        variant: 'info',
      },
      isConnected: isIntegrationConnected(IntegrationType.Klaviyo),
      loading: false,
      onClick: onKlaviyoConnectClick,
    },
    {
      description:
        "Seamlessly add Proxima's custom audiences to your ad account and benchmark your TikTok performance against similar businesses.",
      title: 'TikTok',
      icon: <TikTok />,
      type: IntegrationType.TikTok,
      pill: {
        label: 'Early access',
        variant: 'info',
      },
      isConnected: isIntegrationConnected(IntegrationType.TikTok),
      loading: false,
      onClick: onLoginToTiktok,
    },
    {
      excludeIntegrationStatus: true,
      description:
        'Expedite the training of your Predictive CAPI pixel to get results faster. Only send high-value events to Meta, filtering out the rest and maximizing your signal.',
      title: 'Angler AI',
      icon: <Angler />,
      isConnected: false,
      loading: false,
      onClick: onConnectAnglerClick,
      isUnavailable: false,
    },
    {
      description:
        "Seamlessly add Proxima's custom audiences to your ad account and benchmark your Google Ads performance against similar businesses.",
      title: 'Google Ads',
      icon: <GoogleAds />,
      pill: {
        label: 'Coming soon',
      },
      // TODO(Jenky): TBD
      isConnected: false,
      loading: false,
      onClick: () => {},
      isUnavailable: true,
    },
  ];

  const featureFlagFilters = [
    {
      featureFlag: tiktokIntegration,
      integrationType: IntegrationType.TikTok,
    },
  ];

  const filteredIntegrationItems: IntegrationItems = integrationsItems.filter((integrationItem) => {
    // Show if integration has no type.
    if (!integrationItem.type) {
      return true;
    }

    // Determine if there is a feature flag for the integration.
    const featureFlagFilter = featureFlagFilters.find(
      ({ integrationType }) => integrationType === integrationItem.type
    );

    // Show if there is no feature flag.
    if (!featureFlagFilter) {
      return true;
    }

    // Do not show if feature flag is turned off.
    if (!featureFlagFilter.featureFlag) {
      return false;
    }

    // Show by default.
    return true;
  });

  const onContinueClick = () => {
    navigate(`/brand/${brandId}/audiences`, { replace: true });
  };

  const onSkipClick = () => {
    navigate('/brands', { replace: true });
  };

  useEffect(() => {
    initializeIntegrations();
  }, []);

  return (
    <OnboardingView
      integrationsItems={filteredIntegrationItems}
      onContinue={onContinueClick}
      onSkip={onSkipClick}
    />
  );
};

export default Onboarding;
