import { Text } from '@innovationdepartment/proxima-ui';
import pluralize from 'pluralize';
import {
  AudienceTableCellRendererProps,
  LookalikeAudienceStatus,
  SeedAudienceStatus,
  SeedAudienceTableRowProps,
} from 'types/components/audiences';
import VerticalWrapper from './AudienceRowCell.Wrapper';
import AudienceRowCellNameWrapper from './AudienceRowCell.NameWrapper';

const AudienceRowCellSeedAudience = ({
  row,
}: AudienceTableCellRendererProps<SeedAudienceTableRowProps>) => {
  const lookalikeText = pluralize('lookalike', row?.lookalikeAudiences?.length ?? 0, true);

  const hasError =
    row?.lookalikeAudiences?.some(
      (lookalike) => lookalike.displayStatus === LookalikeAudienceStatus.Error
    ) || row?.status === SeedAudienceStatus.Error;

  return (
    <VerticalWrapper>
      <AudienceRowCellNameWrapper rowName={row!.name as string} error={hasError} />
      <Text variant="table2" color="neutral60">
        {lookalikeText}
      </Text>
    </VerticalWrapper>
  );
};

export default AudienceRowCellSeedAudience;
