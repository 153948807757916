import { IntegrationType } from 'constants/integrations';

export const allowedKeys = ['name', 'accountId', 'integrationId', 'status'] as const;

export enum AdAccountStatus {
  Active = 'active',
  Inactive = 'inactive',
}

type Keys = (typeof allowedKeys)[number];

type AdAccountBase = Omit<{ [key in Keys]: string }, 'status'> & { status: AdAccountStatus };

type AdAccountProperties = {
  acceptedTos: boolean;
};

export type AdAccount = AdAccountBase & { properties: Partial<AdAccountProperties> } & {
  adPixels?: AdPixel[];
};

export type AdAccountStore = {
  metaAdAccount: AdAccount | null;
  tiktokAdAccount: AdAccount | null;
  clear: (type: IntegrationType) => void;
  updateAdAccount: (adAccount: Partial<AdAccount>, type: IntegrationType) => void;

  metaAdAccountsList: AdAccount[];
  tiktokAdAccountsList: AdAccount[];
  getActiveAdAccountFromList: (type: IntegrationType) => AdAccount | undefined;
  updateAdAccountsList: (adAccounts: AdAccount[], type: IntegrationType) => void;
};

export type AdPixel = {
  id: string;
  name: string;
  creationTime: Date | string;
  createdAt: Date | string;
  status: AdPixelStatus;
};

export enum AdPixelStatus {
  Active = 'active',
  Deleted = 'deleted',
}
