import { FacebookSmall, ShopifySmall, TikTokSmall } from '@innovationdepartment/proxima-ui';
import { IntegrationType } from 'constants/integrations';

const PLATFORMS: { [key in IntegrationType]: React.ReactNode } = {
  [IntegrationType.Facebook]: <FacebookSmall />,
  [IntegrationType.TikTok]: <TikTokSmall />,
  [IntegrationType.Shopify]: <ShopifySmall />,
  [IntegrationType.Klaviyo]: undefined,
  [IntegrationType.Angler]: undefined,
  [IntegrationType.ManualImport]: undefined,
};

export default PLATFORMS;
