import {
  SeedAudienceTableColumnHeader,
  SeedAudiencesTableProps,
  SeedAudience,
  SeedAudienceTableRowProps,
  AudienceTableCellRendererProps,
  SeedAudienceTableRowExtraFunctions,
  SeedAudienceStatus,
  LookalikeAudienceStatus,
} from 'types/components/audiences';
import React from 'react';
import { AllowedColors, TableProps, colors } from '@innovationdepartment/proxima-ui';
import SeedAudiencesTableView from './SeedAudiencesTable.View';
import SeedAudiencesTableEmptyContent from './SeedAudiencesTable.EmptyContent';
import LookalikeAudiencesTable from '../LookalikeAudiencesTable';
import seedAudiencesRowRenderer from './seedAudiencesRowRenderer';
import { useFeatureFlag } from 'hooks';

const SeedAudiencesTable = (props: SeedAudiencesTableProps) => {
  const [hoverId, setHoverId] = React.useState<string | null>(null);
  const { onRetryAudience, seedAudiences, showCreateLookalikeModal, showCreateModal } = props;

  const { tiktokIntegration } = useFeatureFlag();

  const headerOrder = [
    'seedAudience',
    'platform',
    'displayStatus',
    'dateCreated',
    'actions',
  ] satisfies SeedAudienceTableColumnHeader[];

  if (!tiktokIntegration) headerOrder.splice(1, 1);

  const config: TableProps<SeedAudience>['config'] = {
    columns: {
      order: headerOrder,
      seedAudience: {
        label: 'Seed audience',
      },
      platform: {
        label: 'Platform',
        width: 160,
      },
      displayStatus: {
        label: 'Status',
        width: 200,
      },
      dateCreated: {
        label: 'Date created',
        align: 'right',
        width: 140,
      },
      actions: {
        width: 40,
        hideLabel: true,
      },
    },
    cellRenderer(rowProps) {
      type RowRendererProps = AudienceTableCellRendererProps<SeedAudienceTableRowProps> &
        SeedAudienceTableRowExtraFunctions;
      return seedAudiencesRowRenderer({
        ...rowProps,
        showCreateLookalikeModal,
        onRetryAudience,
      } as RowRendererProps);
    },
    // eslint-disable-next-line
    emptyStateRenderer: () => (
      <SeedAudiencesTableEmptyContent
        seedAudiences={seedAudiences}
        showCreateModal={showCreateModal}
      />
    ),
    onRowMouseEnter: ({ id }) => {
      setHoverId(id);
    },
    onRowMouseLeave: () => {
      setHoverId(null);
    },
    formatter(row) {
      return {
        ...row,
        isHovered: row.id === hoverId,
      };
    },
    // eslint-disable-next-line
    onRowExpand({ row }) {
      return (
        <LookalikeAudiencesTable
          seedAudience={row!}
          onRetryAudience={onRetryAudience}
          lookalikeAudiences={row?.lookalikeAudiences ?? []}
        />
      );
    },
    rowColor(row) {
      let color: AllowedColors | undefined;
      const isError =
        row.status === SeedAudienceStatus.Error ||
        row.lookalikeAudiences.some(
          (lookalike) => lookalike.displayStatus === LookalikeAudienceStatus.Error
        );

      if (isError) color = colors.red0 as AllowedColors;

      return color;
    },
  };

  return <SeedAudiencesTableView seedAudiences={seedAudiences} config={config} />;
};

export default SeedAudiencesTable;
