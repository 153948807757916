import styled from '@emotion/styled';
import { Banner, SearchBox, SpaceDivider, Text } from '@innovationdepartment/proxima-ui';
import { AdAccount } from 'types/stores/ad-account-store';
import NoAds from './NoAds';
import AdSetupFooter from './Footer/AdAccountSelectionFooter.Meta.Container';
import AdsSetupSearchItem from './AdAccountSelection.SearchItem';
import { IntegrationType } from 'constants/integrations';
import TiktokAdSetupFooter from './Footer/AdAccountSelectionFooter.Tiktok.Container';

type AdAccountSelectionViewProps = {
  brandId: string;
  selectedAdAccount: AdAccount | undefined;
  adAccounts: AdAccount[];
  isLoading: boolean;
  isSaving: boolean;
  isError: boolean;
  redirectTo: string;
  integrationType: IntegrationType;
  setSelectedAdAccount: (adAccount: AdAccount) => void;
  saveAdAccount: () => void;
  refreshAdAccounts?: () => void;
};

type BannerType = 'error' | 'noAds';
type BannerIntegrationType = 'facebook' | 'tiktok';

const BannerWrapper = styled.div`
  margin-bottom: 24px;
`;

const Container = styled.div`
  max-width: 640px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
`;

const NoAdsFoundInSearch = styled.div`
  height: 100%;
  display: grid;
  place-content: center;
`;

const SupportEmailLink = <a href="mailto:hello@proxima.ai">hello@proxima.ai</a>;

const banners = {
  error: {
    facebook: {
      title: 'Ad account already in use',
      body: (
        <span>
          The Meta ad account you selected is already in use. Please select another or request an
          invite from the account owner. Contact {SupportEmailLink} for support.
        </span>
      ),
    },
    tiktok: {
      title: 'Ad account already in use',
      body: (
        <span>
          The TikTok ad account you selected is already in use. Please select another or request an
          invite from the account owner. Contact {SupportEmailLink} for support.
        </span>
      ),
    },
  },
  noAds: {
    facebook: {
      title: 'There are no ad accounts connected to your Meta account',
      body: 'Please refresh or try a different Meta account.',
    },
    tiktok: {
      title: 'There are no ad accounts connected to your TikTok account',
      body: 'Please try a different TikTok account.',
    },
  },
};

const renderBanner = (type: BannerType, integrationType: BannerIntegrationType) => (
  <BannerWrapper>
    <Banner type="error" {...banners[type][integrationType]} />
  </BannerWrapper>
);

const AdAccountSelectionView = ({
  brandId,
  selectedAdAccount,
  adAccounts,
  isLoading,
  isSaving,
  isError,
  integrationType,
  redirectTo,
  saveAdAccount,
  refreshAdAccounts,
  setSelectedAdAccount,
}: AdAccountSelectionViewProps) => {
  const noAds = adAccounts.length === 0 && !isLoading;

  let content = (
    <div style={{ height: 376 }}>
      <SearchBox
        items={adAccounts}
        searchCriteria="name"
        renderItem={(adAccount: AdAccount) => (
          <AdsSetupSearchItem
            selected={adAccount.accountId === selectedAdAccount?.accountId}
            key={adAccount.accountId}
            onClick={() => setSelectedAdAccount(adAccount)}
            adAccount={adAccount}
          />
        )}
        renderEmpty={() => (
          <NoAdsFoundInSearch>
            <Text variant="body1">
              {isLoading
                ? 'Fetching ad accounts...'
                : 'No ad accounts found. Try a different search.'}
            </Text>
          </NoAdsFoundInSearch>
        )}
      />
    </div>
  );

  if (noAds) content = <NoAds />;

  const isTiktok = integrationType === IntegrationType.TikTok;
  const isMeta = integrationType === IntegrationType.Facebook;

  return (
    <Container>
      {isError && renderBanner('error', integrationType as BannerIntegrationType)}
      {noAds && renderBanner('noAds', integrationType as BannerIntegrationType)}
      <Text variant="h5">
        {' '}
        Select {integrationType === IntegrationType.Facebook ? 'Meta' : 'TikTok'} ad account{' '}
      </Text>
      <SpaceDivider />
      <Text variant="body1"> Choose the ad account you would like to use with Proxima </Text>
      <SpaceDivider y={8} />
      {content}
      {isMeta && (
        <AdSetupFooter
          redirectTo={redirectTo}
          brandId={brandId}
          isLoading={isLoading}
          isSaving={isSaving}
          selectedAdAccount={selectedAdAccount}
          saveAdAccount={saveAdAccount}
          refreshAdAccounts={refreshAdAccounts!}
        />
      )}
      {isTiktok && (
        <TiktokAdSetupFooter
          redirectTo={redirectTo}
          isLoading={isLoading}
          isSaving={isSaving}
          selectedAdAccount={selectedAdAccount}
          saveAdAccount={saveAdAccount}
        />
      )}
    </Container>
  );
};
export default AdAccountSelectionView;
