import { TikTok } from '@innovationdepartment/proxima-ui';
import { TiktokIntegrationModalProps } from 'types/components/integrations';
import IntegrationsModal from '../IntegrationsModal';
import TiktokIntegrationModalSideContent from './TiktokIntegrationModal.SideContent';
import TiktokIntegrationModalMainView from './TiktokIntegrationModal.MainContent';

const TiktokIntegrationModalView: React.FC<TiktokIntegrationModalProps> = (props) => {
  const {
    adAccount,
    status,
    loading,
    onClose,
    onConnectAdAccountClick,
    onDisconnectClick,
    onGetStartedClick,
    open,
  } = props;

  return (
    <IntegrationsModal
      icon={<TikTok />}
      title="TikTok"
      open={open}
      onClose={onClose}
      sideContent={
        <TiktokIntegrationModalSideContent
          loading={loading}
          adAccount={adAccount}
          status={status}
          onConnectAdAccountClick={onConnectAdAccountClick}
          onDisconnectClick={onDisconnectClick}
          onGetStartedClick={onGetStartedClick}
        />
      }
    >
      <TiktokIntegrationModalMainView />
    </IntegrationsModal>
  );
};

export default TiktokIntegrationModalView;
