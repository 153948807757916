import { Route, Routes } from 'react-router-dom';
import FacebookOAuthSuccessCallback from './Facebook/FacebookOAuthSuccessCallback.Container';
import ShopifyOAuthSuccessCallback from './Shopify/OAuthSuccess/ShopifyOAuthSuccessCallback.Container';
import ShopifyGenerateOAuthUrl from './Shopify/GenerateOAuthUrl/ShopifyGenerateOAuthUrl.Container';
import ChooseBrandForShopify from './Shopify/ChooseBrandForShopify';
import TiktokOAuthSuccessCallback from './Tiktok/OAuthSuccess/TiktokOAuthSuccessCallback.Container';
import { TiktokAdAccountSelection } from 'components/AdAccountSelection';

const CallbacksRouter = () => (
  <Routes>
    <Route path="meta" element={<FacebookOAuthSuccessCallback />} />
    {/* handle redirect from TikTok auth URL */}
    <Route path="tiktok/integrate" element={<TiktokOAuthSuccessCallback />} />
    {/* select an ad account to use TikTok with */}
    <Route path="tiktok/choose-ad-account" element={<TiktokAdAccountSelection />} />
    {/* Shopify's oauth url generation route */}
    <Route path="shopify/generate-oauth-url" element={<ShopifyGenerateOAuthUrl />} />
    {/* Shopify's already configured callback route - redirect to brand pick */}
    <Route path="shopify" element={<ShopifyOAuthSuccessCallback pickBrand />} />
    {/* select a brand to integrate Shopify with */}
    <Route path="shopify/choose-brand" element={<ChooseBrandForShopify />} />
    {/* previous oauth success route */}
    <Route path="shopify/integrate" element={<ShopifyOAuthSuccessCallback />} />
  </Routes>
);

export default CallbacksRouter;
