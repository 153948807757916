import { IntegrationType } from 'constants/integrations';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useEffect, useRef, useState } from 'react';
import { useAdAccountStore, useBrandStore } from 'stores';
import { useAudiencesApi, useFacebookApi } from 'api';
import { useAdManager, useIntegrations, useQuery, useShowSpinner } from 'hooks';
import CreateNewAudiencesModal from 'ui/Modals/CreateNewAudiencesModal';
import NoAvailableAdAccountsDetectedModal from 'ui/Modals/NoAvailableAdAccountsDetectedModal';
import CreateNewLookalikeModal from 'ui/Modals/CreateNewLookalikeModal';
import CustomAudienceTermsNotAcceptedModal from 'ui/Modals/CustomAudienceTermsNotAcceptedModal';
import {
  AudienceStatus,
  AudiencesQueryStringKeys,
  CreateLookalikeAudienceSchema,
  CreateSeedAudienceResponse,
  CreateSeedAudienceSchema,
  FetchSeedAudienceSchema,
  LookalikeAudience,
  ModalStep,
  SeedAudience,
} from 'types/components/audiences';
import FreeTrialModal from 'ui/Modals/FreeTrialModal';
import { IntegrationStatus } from 'types/stores/integration-store';

import {
  AudiencesCampaign,
  CampaignStatus,
  CampaingInput,
} from 'types/components/audienceCampaign';

import AudiencesView from './Audiences.View';
import BuildPlanModal from 'components/Billing/Stripe/Subscriptions/BuildPlanModal/BuildPlanModal.Container';

const Audiences = () => {
  const timeoutRef = useRef<number>(-1);
  const [seedAudiences, setSeedAudiences] = useState<SeedAudience[]>([]);
  const [galaxies, setGalaxies] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showManageSubscriptionModal, setShowManageSubscriptionModal] = useState(false);
  const [showNoAdsModal, setShowNoAdsModal] = useState(false);
  const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);
  const [showTermsNotAcceptedModal, setShowTermsNotAcceptedModal] = useState(false);
  const [selectedSeedAudience, setSelectedSeedAudience] = useState<SeedAudience>();
  const [stepType, setStepType] = useState<ModalStep>(ModalStep.Audience);
  const [campaignStatus, setCampaignStatus] = useState<CampaignStatus>(CampaignStatus.New);

  const { showToaster } = useToaster();
  const { getQueryParams } = useQuery();
  const { brand } = useBrandStore();
  const { getAdAccount, getAdPixels, getActiveAdPixelsByCreatedDate } = useAdManager();
  const { loading: fbApiLoading, areCustomAudienceTosAccepted } = useFacebookApi();
  const params = getQueryParams<AudiencesQueryStringKeys>();

  const {
    loading: audiencesLoading,
    getAvailableGalaxies,
    createSeedAudiences,
    createLookalikeAudience,
    retryLookalikeAudience,
    createCampaign,
  } = useAudiencesApi();
  const {
    loading: seedAudiencesLoading,
    getSeedAudiences,
    getCampaignByAudienceGroupId,
  } = useAudiencesApi();
  const { loading: integrationsLoading, getActiveIntegrations } = useIntegrations();

  const { metaAdAccount } = useAdAccountStore();

  const [fbIntegration] = getActiveIntegrations(IntegrationType.Facebook);

  /* state management for newly created seed audiences */
  const [newCreated, setNewCreated] = useState(false);

  const loading = seedAudiencesLoading || audiencesLoading || integrationsLoading || fbApiLoading;

  useShowSpinner({ show: loading });

  const canCreateAudiences =
    fbIntegration?.status === IntegrationStatus.Active && Boolean(metaAdAccount);

  const hasAcceptedCustomAudienceTos = metaAdAccount?.properties?.acceptedTos ?? false;

  // remove galaxies after modal is closed, that way we simulate that they're "processing"
  const simulateLookalikesAreProcessing = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      if (newCreated) setGalaxies([]);
    }, 250);
  };

  const toggleNoAdsModal = (show = true) => {
    setShowNoAdsModal(show);
  };

  const toggleFreeTrialModal = (show = true) => {
    setShowFreeTrialModal(show);
  };

  const toggleCreateLookalikeModal = (row?: SeedAudience) => {
    if (brand.status === 'locked') return toggleFreeTrialModal();

    if (canCreateAudiences) {
      return setSelectedSeedAudience(row);
    }
    return toggleNoAdsModal();
  };

  const fetchSeedAudiences = async () => {
    const response = await getSeedAudiences();

    if (response.error) return;

    const seedsResponse = response.data as FetchSeedAudienceSchema;
    const seedAudiencesData = seedsResponse.seedAudiences;

    const seedAudiencesWithIntegrationTypes = seedAudiencesData.map((seedAudience) => {
      const { integrationAudiences } = seedAudience;

      const integrationTypes = integrationAudiences.map(
        (integrationAudience) => integrationAudience.integrationType
      );

      const noDuplicateIntegrationTypes = [...new Set(integrationTypes)];

      return { ...seedAudience, integrationTypes: noDuplicateIntegrationTypes };
    });

    setSeedAudiences(seedAudiencesWithIntegrationTypes);
  };

  const status = galaxies.length > 0 ? AudienceStatus.New : AudienceStatus.Ready;

  const fetchAudienceCampaign = async () => {
    setStepType(ModalStep.Audience);
    // skip when audiences are created for first time
    if (!(Array.isArray(seedAudiences) && seedAudiences.length)) {
      return;
    }
    // skip when audiences can be regenerated again
    if (status === AudienceStatus.New) return;

    setCampaignStatus(CampaignStatus.None);
    const { audienceGroupId } = seedAudiences[0];
    const result = await getCampaignByAudienceGroupId(audienceGroupId);
    if (result.error && result.status === 404) {
      return;
    }

    setStepType(ModalStep.Campaign);
    setCampaignStatus(CampaignStatus.Ready);
  };

  const toggleCreateModal = async (show = true) => {
    if (show) await fetchAudienceCampaign();
    setShowCreateModal(show);
    if (!show) simulateLookalikesAreProcessing();
  };

  const [audiencesCampaign, setAudiencesCampaign] = useState<AudiencesCampaign | undefined>();

  const onCreateNewSeedAudiences = async (data: CreateSeedAudienceSchema) => {
    if (!hasAcceptedCustomAudienceTos) {
      setShowCreateModal(false);
      setShowTermsNotAcceptedModal(true);
      return undefined;
    }

    const response = await createSeedAudiences(data);

    if (response.error) {
      // TODO(Jenky): discuss with product
      const errorMsg =
        'Something happened when creating your lookalike audiences. Please try again later.';
      showToaster({ message: errorMsg, variant: 'error' });
    } else {
      // TODO(Jenky): disabled
      // proceessSeedsAudiences(response.data as SeedAudience[]);
      const { audienceGroupId } = response.data as unknown as CreateSeedAudienceResponse;
      const { size } = data.integrations[0];
      const adPixels = await getAdPixels();
      const adPixelOptions = getActiveAdPixelsByCreatedDate(adPixels);
      setAudiencesCampaign({
        size,
        audienceGroupId,
        adPixels: adPixelOptions,
      } as unknown as AudiencesCampaign);
      setStepType(ModalStep.Campaign);
      setCampaignStatus(CampaignStatus.New);
      fetchSeedAudiences();
      setNewCreated(true);
    }
    return response;
  };

  const onCreateNewLookalikeAudience = async (
    sizeData: Omit<CreateLookalikeAudienceSchema, 'seedAudienceId'>
  ) => {
    if (!selectedSeedAudience) return;

    const data = {
      size: +sizeData.size,
      integrationType: sizeData.integrationType,
      seedAudienceId: selectedSeedAudience.id,
    } satisfies CreateLookalikeAudienceSchema;

    const response = await createLookalikeAudience(data);

    if (response.error) {
      // TODO(Jenky): discuss with product
      const errorMsg =
        'Something happened when creating your lookalike audiences. Please try again later.';
      showToaster({ message: errorMsg, variant: 'error' });
    } else {
      const lookalikeAudience = response.data as LookalikeAudience;

      setSeedAudiences(
        seedAudiences.map((seed) => {
          if (seed.id === selectedSeedAudience.id) {
            return {
              ...seed,
              lookalikeAudiences: [...seed.lookalikeAudiences, lookalikeAudience],
            };
          }

          return seed;
        })
      );
    }
  };

  const onRetryAudience = async ({
    seedAudienceId,
    lookalikeAudienceId,
  }: {
    seedAudienceId: string;
    lookalikeAudienceId?: string;
  }) => {
    if (lookalikeAudienceId) {
      await retryLookalikeAudience({ seedAudienceId, lookalikeAudienceId });
    } else {
      // unlikely to get in here since no action for this is attached to any element
      // logic was commented out on <AudienceRowCellStatus />
      // TODO(Jenky): add logic to retry seed audience
    }

    fetchSeedAudiences();
  };

  // checks if previous action was from 'generate new audiences' modal
  // ex. user trying to connect to Shopify and we want to resume the flow
  const checkIfRedirectedFromShopify = () => {
    const { generateAudiences } = params;

    if (generateAudiences === 'true') toggleCreateModal(true);
  };

  // only do this when GenerateNewAudiencesModal shows up
  // check useEffect
  const requestAdAccount = async () => {
    // unlikely, but if ad account is not set don't do anything
    if (!metaAdAccount) return;
    // only perform action if ad account has not accepted TOS
    if (!metaAdAccount.properties?.acceptedTos) {
      // check for terms of service
      const acceptedTos = await areCustomAudienceTosAccepted();

      if (acceptedTos) {
        // refresh ad account store
        getAdAccount({ refresh: true });
      }
    }
  };

  const onCreateNewCampaign = async (input: CampaingInput, audienceGroupId: string) => {
    const response = await createCampaign(input, audienceGroupId);
    if (response.error) {
      const errorMsg = 'Something happened when creating your campaign. Please try again later.';
      showToaster({ message: errorMsg, variant: 'error' });
    }
    return response;
  };

  /* fetch available galaxies */
  useEffect(() => {
    const fetchNewGalaxies = async () => {
      const response = await getAvailableGalaxies();
      if (response.error) return;
      setGalaxies(response.data);

      checkIfRedirectedFromShopify();
    };

    fetchNewGalaxies();
  }, []);

  /* fetch all seed audiences */
  useEffect(() => {
    fetchSeedAudiences();
  }, []);

  const toggleAdAccountModal = canCreateAudiences ? toggleCreateModal : toggleNoAdsModal;
  const toggleModal = brand.status === 'locked' ? toggleFreeTrialModal : toggleAdAccountModal;

  return (
    <>
      <AudiencesView
        onRetryAudience={onRetryAudience}
        seedAudiences={seedAudiences}
        showCreateModal={toggleModal}
        showCreateLookalikeModal={toggleCreateLookalikeModal}
        showNewGalaxiesBadge={status === AudienceStatus.New}
      />
      <CreateNewAudiencesModal
        status={status}
        show={showCreateModal}
        onClose={() => toggleCreateModal(false)}
        onCreateNewSeedAudiences={onCreateNewSeedAudiences}
        requestAdAccount={requestAdAccount}
        campaignStatus={campaignStatus}
        audiencesCampaign={audiencesCampaign}
        onCreateNewCampaign={onCreateNewCampaign}
        stepType={stepType}
      />
      <CreateNewLookalikeModal
        seedAudience={selectedSeedAudience}
        onCreateNewLookalikeAudience={onCreateNewLookalikeAudience}
        onClose={() => toggleCreateLookalikeModal()}
      />
      <NoAvailableAdAccountsDetectedModal
        show={showNoAdsModal}
        onClose={() => toggleNoAdsModal(false)}
      />
      <CustomAudienceTermsNotAcceptedModal
        show={showTermsNotAcceptedModal}
        onClose={() => setShowTermsNotAcceptedModal(false)}
      />
      <FreeTrialModal show={showFreeTrialModal} onClose={() => setShowFreeTrialModal(false)} />
      {brand && (
        <BuildPlanModal
          brandId={brand.brandId}
          open={showManageSubscriptionModal}
          onClose={() => setShowManageSubscriptionModal(false)}
        />
      )}
    </>
  );
};

export default Audiences;
