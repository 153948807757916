import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProximaLogoWithTextBlack, TikTok, useToaster } from '@innovationdepartment/proxima-ui';
import { usePolling, useProximaSDK, useQuery, useShowSpinner } from 'hooks';
import IntegrationsModal from 'ui/Modals/IntegrationsModal';
import { AdAccount } from 'types/stores/ad-account-store';
import { useBrandStore } from 'stores';
import AdAccountSelectionView from './AdAccountSelection.View';
import { IntegrationType } from 'constants/integrations';

type AdAccountSelectionProps = {
  /**
   * only used for testing purposes - to mock the polling time
   * @default 1000
   * */
  polling?: number;
};

const TiktokAdAccountSelection = (props: AdAccountSelectionProps) => {
  const { polling = 1000 } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { showToaster } = useToaster();
  const { pollUntilCondition } = usePolling();
  const { getQueryParams } = useQuery();
  const tiktokIntegrationApi = useProximaSDK('TiktokIntegrationApi');

  const { brandId: queryParamBrandId } = getQueryParams();
  const { brand } = useBrandStore();

  const brandId = queryParamBrandId || brand.brandId;

  /* internal state */
  const [selectedAdAccount, setSelectedAdAccount] = useState<AdAccount>();
  const [adAccounts, setAdAccounts] = useState<AdAccount[]>([]);
  /* flags */
  const [isFetchingAdAccounts, setIsFetchingAdAccounts] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);

  const saveAdAccount = async (adAccount: AdAccount) => {
    if (!adAccount) return;

    setIsError(false);
    setIsSaving(true);

    try {
      await tiktokIntegrationApi.updateAdAccount1({
        brandId,
        adAccountId: adAccount.accountId,
      });

      setIsSaving(false);

      navigate(`/brand/${brandId}/integrations`);

      return;
    } catch (err) {
      setIsError(true);
    }
    setIsSaving(false);
  };

  const fetchAdAccounts = () => {
    const pollLimit = 30;
    let pollCount = 0;
    let stopPolling = false;

    const fetchAndProcessAdAccounts = async () => {
      const actualPollCount = pollCount - 1;
      pollCount += 1;

      setIsFetchingAdAccounts(true);

      try {
        const response = await tiktokIntegrationApi.getTiktokAdAccounts({ brandId });

        const isAdAccountEmpty = !Array.isArray(response.data) || response.data?.length === 0;

        stopPolling = actualPollCount >= pollLimit;

        if (!isAdAccountEmpty) {
          stopPolling = true;

          setIsFetchingAdAccounts(false);
          setAdAccounts(response.data as AdAccount[]);
          return;
        }

        if (stopPolling) {
          showToaster({
            variant: 'warning',
            message: 'Unable to fetch ad accounts. Please refresh the page.',
          });

          setIsFetchingAdAccounts(false);
        }
      } catch (err) {
        stopPolling = true;

        setIsFetchingAdAccounts(false);
      }
    };

    pollUntilCondition({
      cb: fetchAndProcessAdAccounts,
      condition: () => stopPolling,
      waitForFirstCall: true,
      timeout: polling,
    })();
  };

  useEffect(() => {
    fetchAdAccounts();
  }, []);

  let icon: React.ReactNode = <TikTok />;
  let title: React.ReactNode = 'TikTok';

  const isSetup = location.pathname.includes('/setup');

  if (isSetup) {
    icon = null;
    title = (
      <div style={{ marginLeft: 48, display: 'flex' }}>
        <ProximaLogoWithTextBlack />
      </div>
    );
  }

  useShowSpinner({ show: tiktokIntegrationApi.loading });

  return (
    <IntegrationsModal
      icon={icon}
      title={title}
      full
      onClose={isSetup ? undefined : () => navigate(`/brand/${brandId}/integrations`)}
      open
    >
      <AdAccountSelectionView
        brandId={brandId}
        integrationType={IntegrationType.TikTok}
        isLoading={isFetchingAdAccounts || tiktokIntegrationApi.loading}
        isSaving={isSaving}
        isError={isError}
        saveAdAccount={() => selectedAdAccount && saveAdAccount(selectedAdAccount)}
        adAccounts={adAccounts}
        selectedAdAccount={selectedAdAccount}
        setSelectedAdAccount={setSelectedAdAccount}
        redirectTo={`/brand/${brandId}/integrations`}
      />
    </IntegrationsModal>
  );
};

export default TiktokAdAccountSelection;
