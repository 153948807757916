import { CustomCard, Text } from '@innovationdepartment/proxima-ui';

import SectionLayout from 'ui/SectionLayout';
import { IntegrationViewProps } from './types';
import styled from '@emotion/styled';

const headerContent = (
  <Text variant="body1" color="neutral70">
    Sync data to power other platforms and optimize targeting algorithms.
  </Text>
);

const IntegrationWrapper = styled.div`
  align-self: flex-start;
`;

const IntegrationsView = ({ integrations }: IntegrationViewProps) => (
  <SectionLayout title="Integrations" headerContent={headerContent}>
    {integrations.map((integration) => (
      <IntegrationWrapper key={integration.label as string}>
        <CustomCard {...integration} />
      </IntegrationWrapper>
    ))}
  </SectionLayout>
);

export default IntegrationsView;
