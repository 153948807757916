import { useEffect } from 'react';
import { debounce } from 'lodash';
import { useTiktok } from 'hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useBrandStore } from 'stores';
import AllSetView from 'components/Auth/AllSet/AllSet.View';

const debouncer = debounce((cb) => cb(), 500);

const TiktokOAuthSuccessCallback = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const { brand } = useBrandStore();
  const { setTiktokAccessToken } = useTiktok();

  useEffect(() => {
    debouncer(() => {
      if (isLoading) return;

      setTiktokAccessToken();
    });
  }, [isAuthenticated, isLoading, brand]);

  return <AllSetView isAuthenticated={false} onClick={() => {}} />;
};

export default TiktokOAuthSuccessCallback;
