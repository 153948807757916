import { IntegrationType } from 'constants/integrations';
import { useEffect, useState } from 'react';
import { useAdAccountStore } from 'stores';
import { useTiktok, useIntegrations, useShowSpinner, useProximaSDK } from 'hooks';
import { TiktokIntegrationModalProps } from 'types/components/integrations';
import DisconnectIntegrationModal from 'ui/Modals/DisconnectIntegrationModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TiktokIntegrationModalView from './TiktokIntegrationModal.View';
import { AdAccount, AdAccountStatus } from 'types/stores/ad-account-store';

const TiktokIntegrationModal = (props: Pick<TiktokIntegrationModalProps, 'open' | 'onClose'>) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { brandId } = useParams<{ brandId: string }>();
  const { deleteIntegration, getActiveIntegrations, loading } = useIntegrations();
  const { getTiktokStatus, onLoginToTiktok } = useTiktok();
  const adAccountStore = useAdAccountStore();
  const tiktokIntegrationApi = useProximaSDK('TiktokIntegrationApi');

  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const [ttAdAccount, setTtAdAccount] = useState<AdAccount>();

  const [tiktokIntegration] = getActiveIntegrations(IntegrationType.TikTok);

  useShowSpinner({ show: loading });

  const onDisconnectClick = () => setDisconnectModalOpen(true);

  const onDisconnectConfirm = async () => {
    if (tiktokIntegration) {
      setDisconnectModalOpen(false);

      await deleteIntegration(tiktokIntegration.integrationId);

      adAccountStore.clear(IntegrationType.TikTok);
    }
  };

  const onConnectAdAccountClick = () => {
    navigate(`/brand/${brandId}/tiktok/ad-account?redirect=${pathname}`);
  };

  const tiktokIntegrationStatus = getTiktokStatus();

  useEffect(() => {
    const getAdAccount = async () => {
      const ttAdAccountRes = await tiktokIntegrationApi.getCurrentTiktokAdAccount(
        {
          brandId: brandId!,
        },
        { skipErrorToaster: true }
      );

      if (ttAdAccountRes)
        adAccountStore.updateAdAccount(ttAdAccountRes.data, IntegrationType.TikTok);

      setTtAdAccount({
        name: ttAdAccountRes.data.name,
        accountId: ttAdAccountRes.data.adAccountId,
        integrationId: ttAdAccountRes.data.integrationId,
        status: AdAccountStatus.Active,
        properties: {},
      });
    };

    getAdAccount();
  }, []);

  return (
    <>
      <TiktokIntegrationModalView
        brandId={brandId as string}
        status={tiktokIntegrationStatus}
        onConnectAdAccountClick={onConnectAdAccountClick}
        onDisconnectClick={onDisconnectClick}
        adAccount={ttAdAccount!}
        loading={loading}
        open={open}
        onClose={onClose}
        onGetStartedClick={() => onLoginToTiktok()}
      />
      <DisconnectIntegrationModal
        type={IntegrationType.TikTok}
        open={disconnectModalOpen}
        close={() => setDisconnectModalOpen(false)}
        confirm={onDisconnectConfirm}
      />
    </>
  );
};

export default TiktokIntegrationModal;
