import styled from '@emotion/styled';
import { SpaceDivider, Text, Button, SwitchUser, colors } from '@innovationdepartment/proxima-ui';
import { useTiktok } from 'hooks';
import { AdAccount } from 'types/stores/ad-account-store';
import LoadingSpinner from 'ui/LoadingSpinner';
import ProximaLink from 'ui/ProximaLink';

export type TiktokAdAccountSelectionFooterProps = {
  isLoading: boolean;
  isSaving: boolean;
  selectedAdAccount: AdAccount | undefined;
  redirectTo: string;
  saveAdAccount: () => void;
};

const ButtonGroup = styled.div<{ flex?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  ${({ flex }) => (flex ? 'flex: 1;' : '')}
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.neutral80};
`;

const TiktokAdSetupFooter = ({
  isLoading,
  isSaving,
  selectedAdAccount,
  redirectTo,
  saveAdAccount,
}: TiktokAdAccountSelectionFooterProps) => {
  const disableButtons = isLoading || isSaving;
  const { onLoginToTiktok } = useTiktok();

  return (
    <>
      <SpaceDivider y={1} />
      <ButtonGroup>
        <Button
          disabled={disableButtons}
          size="small"
          variant="text"
          label="Switch User"
          color="secondary"
          startIcon={<SwitchUser />}
          onClick={onLoginToTiktok}
        />
      </ButtonGroup>
      <SpaceDivider y={4} />
      <Divider />
      <SpaceDivider y={2} />
      <ButtonGroup>
        <ButtonGroup flex>
          <Text variant="body2">Not sure which ad account to use?</Text>
          <ProximaLink to={redirectTo}>Skip for now</ProximaLink>
        </ButtonGroup>
        <Button
          startIcon={isSaving ? <LoadingSpinner /> : undefined}
          size="small"
          disabled={disableButtons || !selectedAdAccount}
          label={isSaving ? 'Saving' : 'Connect'}
          onClick={saveAdAccount}
        />
      </ButtonGroup>
    </>
  );
};

export default TiktokAdSetupFooter;
