import {
  AudienceTableCellRendererProps,
  SeedAudienceTableRowProps,
  SeedAudienceTableRowExtraFunctions,
} from 'types/components/audiences';
import {
  AudienceRowCellActions,
  AudienceRowCellDateCreated,
  AudienceRowCellSeedAudience,
  AudienceRowCellStatus,
  SeedAudienceRowCellPlatform,
} from 'components/Audiences/Layout';
import { IntegrationType } from 'constants/integrations';

const seedAudiencesRowRenderer = (
  props: AudienceTableCellRendererProps<SeedAudienceTableRowProps> &
    SeedAudienceTableRowExtraFunctions
) => {
  const { row, header } = props;

  // This hides the actions button on ad hoc TikTok seed audiences
  // It'll probably get removed once we implement the self-service functionality for TikTok audiences
  const shouldShowActions =
    header === 'actions' && !row?.integrationTypes.includes(IntegrationType.TikTok);

  if (!header) return null;

  if (header === 'seedAudience') return <AudienceRowCellSeedAudience {...props} />;

  if (header === 'platform') return <SeedAudienceRowCellPlatform {...props} />;

  if (header === 'displayStatus') return <AudienceRowCellStatus {...props} />;

  if (header === 'dateCreated') return <AudienceRowCellDateCreated date={row?.createdAt} />;

  if (shouldShowActions) return <AudienceRowCellActions {...props} />;

  return <div />;
};

export default seedAudiencesRowRenderer;
