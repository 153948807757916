import React from 'react';
import styled from '@emotion/styled';
import { Text, Tooltip, colors } from '@innovationdepartment/proxima-ui';
import { Dictionary, capitalize } from 'lodash';
import {
  AudienceTableCellRendererProps,
  SeedAudienceTableRowProps,
  LookalikeAudience,
  LookalikeAudienceStatus,
  SeedAudienceTableRowExtraFunctions,
  SeedAudienceStatus,
} from 'types/components/audiences';
import { status as statusUtils } from './utils';
import VerticalWrapper from './AudienceRowCell.Wrapper';
// TODO(Jenky): disabled until we add support to retry seed audiences
// import AudienceRowCellErrorStatus from './AudienceRowCell.ErrorStatus';

const StatusWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StatusIcon = styled.div<{ color: string }>`
  display: grid;
  place-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const StatusPlus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StatusMore = styled.div`
  user-select: none;
  padding: 0 4px;
  border-radius: 16px;
  background-color: ${colors.neutral10};
`;

const Status = ({ count, status }: { count: number; status: string }) => {
  let color = statusUtils.color.default;

  // @ts-ignore
  if (status in statusUtils.color) color = statusUtils.color[status];

  return (
    <StatusWrapper>
      <StatusIcon color={color}>
        <Text variant="tag2Medium" color="neutral80">
          {count}
        </Text>
      </StatusIcon>
      <Text variant="tag2Medium" color="neutral80">
        {capitalize(status)}
      </Text>
    </StatusWrapper>
  );
};

const MoreStatus = ({
  statuses,
  group,
}: {
  statuses: string[];
  group: Dictionary<LookalikeAudience[]>;
}) => {
  if (statuses.length === 0) return null;

  const moreStatuses = (
    <VerticalWrapper>
      {statuses.map((status) => (
        <Text
          // @ts-ignore
          color={statusUtils.color[status] ?? statusUtils.color.default}
          key={status}
          variant="body3Link"
        >{`${group[status].length} ${capitalize(status)}`}</Text>
      ))}
    </VerticalWrapper>
  );

  return (
    <Tooltip title={moreStatuses} placement="right" arrow>
      <StatusMore>
        <Text variant="tag2Medium" color="neutral100">{`+${statuses.length}`}</Text>
      </StatusMore>
    </Tooltip>
  );
};

const TemporaryTooltipUntilWeEnableSeedRetry: React.FC = ({ children }) => (
  <Tooltip
    title={
      <Text variant="tag2Medium" color="white">
        Seed audience unsuccessful.
      </Text>
    }
    placement="left"
    arrow
  >
    {children}
  </Tooltip>
);

// TODO(Jenky): requires tests
const AudienceRowCellStatus = ({
  row,
}: // TODO(Jenky): disabled until we add support to retry seed audiences
// onRetryAudience,
AudienceTableCellRendererProps<SeedAudienceTableRowProps> & SeedAudienceTableRowExtraFunctions) => {
  const group = statusUtils.group(row?.lookalikeAudiences ?? []);
  const sortedStatus = statusUtils.sort(Object.keys(group) as LookalikeAudienceStatus[]);

  const errorsToShow = sortedStatus.slice(0, statusUtils.limit);
  const errorsForTooltip = sortedStatus.slice(statusUtils.limit);

  const isSeedError = row?.status === SeedAudienceStatus.Error;

  // TODO(Jenky): Remove once we enable retry logic
  const StatusTempWrapper = isSeedError ? TemporaryTooltipUntilWeEnableSeedRetry : React.Fragment;

  /*
  // TODO(Jenky): disabled until we add support to retry seed audiences
  // Also, this will also need an unit test
  const onRetry = () => onRetryAudience({ seedAudienceId: row!.id });
  if (isSeedError) return <AudienceRowCellErrorStatus onRetry={onRetry} />;
  */

  return (
    <VerticalWrapper gap={8}>
      {errorsToShow.map((status, i) => (
        <StatusTempWrapper key={status}>
          <StatusPlus>
            <Status key={status} count={group[status].length} status={status} />
            {i === statusUtils.limit - 1 && (
              <MoreStatus group={group} statuses={errorsForTooltip} />
            )}
          </StatusPlus>
        </StatusTempWrapper>
      ))}
    </VerticalWrapper>
  );
};

export default AudienceRowCellStatus;
