import { useFacebookApi } from 'api';
import { useAdAccountStore } from 'stores';
import { useParams } from 'react-router-dom';
import { AdManager, PAGE_DEFAULT_SIZE, PaginationSettings } from 'types/components/fbTable';
import prepareQueryString from 'utils/prepareQueryString';
import { AdAccount, AdPixel, AdPixelStatus } from 'types/stores/ad-account-store';
import { ActiveAdPixel, Option } from 'types/components/audienceCampaign';
import useHandleApi from './useHandleApi';
import { IntegrationType } from 'constants/integrations';

const useAdManager = () => {
  const { loading: handlerLoading, handleApi } = useHandleApi();
  const adAccountStore = useAdAccountStore();
  const { getActiveAdAccount, loading: fbLoading } = useFacebookApi();
  const { brandId: queryBrandId } = useParams();

  const loading = handlerLoading || fbLoading;

  // TODO(Jenky): This is way too messy, we need to move logic to just one place
  const getAdAccount = async (opts?: {
    refresh?: boolean;
    brandId?: string;
  }): Promise<AdAccount | undefined> => {
    const { refresh = false, brandId } = opts ?? {};
    if (refresh) adAccountStore.clear(IntegrationType.Facebook);
    else {
      const currentActiveAdAccount = adAccountStore.getActiveAdAccountFromList(
        IntegrationType.Facebook
      );
      if (currentActiveAdAccount) return currentActiveAdAccount;
    }

    const activeAdAccount = await getActiveAdAccount(brandId ?? (queryBrandId as string));

    if (activeAdAccount) adAccountStore.updateAdAccount(activeAdAccount, IntegrationType.Facebook);

    return activeAdAccount;
  };

  const refreshAdAccount = async ({ brandId }: { brandId: string }) =>
    getAdAccount({ refresh: true, brandId });

  const getApiUrl = async () => {
    const adAccount = await getAdAccount();
    return `/brand/${queryBrandId}/fb/adAccount/${adAccount?.accountId}`;
  };

  const handleGetRequest = async <T = AdManager>(adManagerPath: T, opts: PaginationSettings) => {
    const { queryItems, ...restOpts } = opts;
    const queryString = prepareQueryString({
      pageSize: PAGE_DEFAULT_SIZE,
      pageNumber: 1,
      ...restOpts,
      ...queryItems,
    });

    const apiUrl = opts.apiPath ?? (await getApiUrl());
    return handleApi({ endpoint: `${apiUrl}/${adManagerPath}?${queryString}` });
  };

  const getCampaigns = async (opts: PaginationSettings) => {
    const response = await handleGetRequest('campaign', opts);
    return response;
  };

  const getAdSets = async (opts: PaginationSettings) => {
    const response = await handleGetRequest('adset', opts);
    return response;
  };

  const getAds = async (opts: PaginationSettings) => {
    const response = await handleGetRequest('ad', opts);
    return response;
  };

  const getLastUpdatedAt = async () => {
    const adAccount = await getAdAccount();

    const response = await handleApi({
      endpoint: `/brand/${queryBrandId}/integration/facebook/state/${adAccount?.accountId}/last-update`,
    });

    // TODO(Jenky): Address this differently
    if (response.error) {
      return '-';
    }

    return response.data.lastUpdatedAt;
  };

  const getAdPixels = async () => {
    const adAccount = await getAdAccount();
    const adPixels: AdPixel[] = adAccount?.adPixels ?? [];

    return adPixels;
  };

  const refreshAdPixels = async () => {
    const adAccount = await getAdAccount();
    const response = await handleApi({
      endpoint: `/brand/${queryBrandId}/fb/adAccount/${adAccount?.accountId}/adPixel/refresh`,
      method: 'POST',
    });
    const adPixels: AdPixel[] = response.data ?? [];
    return adPixels;
  };

  const getActiveAdPixelsByCreatedDate = (adPixels: AdPixel[]) => {
    const activeAdPixels: ActiveAdPixel[] = [];
    adPixels.forEach((item) => {
      if (item.status === AdPixelStatus.Active) {
        const { id, name, creationTime } = item;
        const createdDate = new Date(creationTime);
        activeAdPixels.push({
          id,
          name,
          createdDate,
        });
      }
    });

    const sortedDesc = activeAdPixels.sort(
      (objA, objB) => Number(objB.createdDate) - Number(objA.createdDate)
    );

    let sortedAdPixelsOptions: Option[] = [];
    sortedAdPixelsOptions = sortedDesc.map((item) => {
      const { id, name } = item;
      return { value: id, label: name };
    });
    return sortedAdPixelsOptions;
  };

  const getPeformanceAds = async (opts: PaginationSettings) => {
    const response = await handleGetRequest<'fb/ads/creatives'>('fb/ads/creatives', {
      ...opts,
      apiPath: '',
    });
    return response;
  };

  return {
    loading,
    refreshAdAccount,
    getLastUpdatedAt,
    getAdAccount,
    getCampaigns,
    getAdSets,
    getAds,
    getPeformanceAds,
    refreshAdPixels,
    getAdPixels,
    getActiveAdPixelsByCreatedDate,
  };
};

export default useAdManager;
