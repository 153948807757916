import {
  LookalikeAudienceTableRowProps,
  AudienceTableCellRendererProps,
} from 'types/components/audiences';
import VerticalWrapper from './AudienceRowCell.Wrapper';
import { PLATFORMS } from './utils';

const AudienceRowCellPlatform = ({
  row,
}: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps>) => (
  <VerticalWrapper>{row?.integrationType ? PLATFORMS[row?.integrationType] : null}</VerticalWrapper>
);

export default AudienceRowCellPlatform;
