export const ROUTES_WITHOUT_NAVBAR = [
  '/',
  '/logout',
  '/setup/user',
  '/setup/brand',
  '/email/verify',
  '/auth/callback/meta',
  '/auth/callback/shopify/generate-oauth-url',
  '/auth/callback/shopify',
  '/auth/callback/shopify/choose-brand',
  '/auth/callback/shopify/integrate',
  '/auth/callback/tiktok/integrate',
  '/auth/callback/tiktok/choose-ad-account',
  '/auth/all-set',
  '/terms/copyright',
  '/terms/privacy',
  '/terms/use',
  '/permissions-required',
  '/brands',
  '/onboarding',
  '/login',
  '/sign-up',
  '/logout',
  '/custom-tos',
  '/ad-account',
  '/klaviyo',
];

export const ROUTES_WITH_BLUEGREEN_BACKDROP = ['/email/verify', '/setup/user', '/setup/brand'];

export const PUBLIC_ROUTES = ['/terms', '/auth/callback/shopify', '/auth/all-set'];
