import { create } from 'zustand';
import pick from 'lodash/pick';

import {
  AdAccount,
  AdAccountStatus,
  AdAccountStore,
  allowedKeys,
} from 'types/stores/ad-account-store';
import { IntegrationType } from 'constants/integrations';

const useAdAccountStore = create<AdAccountStore>((set, get) => ({
  metaAdAccount: null,
  tiktokAdAccount: null,
  properties: {},
  clear: (type: IntegrationType) =>
    set(() => {
      let store: Partial<AdAccountStore> = {};

      if (type === IntegrationType.Facebook) {
        store = { metaAdAccount: null, metaAdAccountsList: [] };
      }

      if (type === IntegrationType.TikTok) {
        store = { tiktokAdAccount: null, tiktokAdAccountsList: [] };
      }

      return store;
    }),
  updateAdAccount: (adAccount: Partial<AdAccount>, type: IntegrationType) =>
    set((prevState) => {
      let store: Partial<AdAccountStore> = {};

      if (type === IntegrationType.Facebook) {
        const { properties: newProperties = {}, adPixels: newAdPixels = [] } = adAccount;
        const { properties: oldProperties = {}, adPixels: oldAdPixels = [] } =
          prevState.metaAdAccount ?? {};

        const properties = { ...oldProperties, ...newProperties };
        const adPixels = [...oldAdPixels, ...newAdPixels];
        const newAdAccount = {
          ...(prevState.metaAdAccount ?? {}),
          ...pick(adAccount, allowedKeys),
          properties,
          adPixels,
        } as AdAccount;

        store.metaAdAccount = { ...prevState.metaAdAccount, ...newAdAccount };
      }

      if (type === IntegrationType.TikTok) {
        const newAdAccount = pick(adAccount, allowedKeys) as AdAccount;

        store = {
          tiktokAdAccount: newAdAccount,
        };
        if (prevState.tiktokAdAccount)
          store.tiktokAdAccount = { ...prevState.tiktokAdAccount, ...newAdAccount };
      }

      return store;
    }),
  // list values
  metaAdAccountsList: [],
  tiktokAdAccountsList: [],
  getActiveAdAccountFromList: (type: IntegrationType) => {
    if (type === IntegrationType.Facebook)
      return get().metaAdAccountsList.find(
        (adAccount) => adAccount.status === AdAccountStatus.Active
      );

    if (type === IntegrationType.TikTok)
      return get().tiktokAdAccountsList.find(
        (adAccount) => adAccount.status === AdAccountStatus.Active
      );

    return undefined;
  },
  updateAdAccountsList: (adAccountsList: AdAccount[], type: IntegrationType) => {
    if (type === IntegrationType.Facebook) set({ metaAdAccountsList: adAccountsList });
    if (type === IntegrationType.TikTok) set({ tiktokAdAccountsList: adAccountsList });
  },
}));

export default useAdAccountStore;
